import React from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import style from "./index.module.css"
import AndvicHeader from "../../../components/andvic-header"
import BurgerMenu from "../../../components/burger-menu"
import SEO from "../../../components/seo"

const BackgroundSection = ({ className }: { className: any }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "commercial.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
          >
            <AndvicHeader></AndvicHeader>
          </BackgroundImage>
        </>
      )
    }}
  />
)

export default function IndexPage(): JSX.Element {
  return (
    <Layout>
      <SEO title="Commercial Services" />
      <BurgerMenu></BurgerMenu>

      <BackgroundSection className={style.header}></BackgroundSection>
      <div className={style.mainHeader}>
        <div>
          <h1 className={style.header1}>Commercial</h1>
        </div>
      </div>
      <div className={style.copy}>
        <div>
          <p>
            Andvic Projects are experienced in providing construction
            contracting services to the Sydney commercial construction sector
            and delivering on time while maintaining quality and WHS&amp;E
            standards. Our team is client focused and listens to the needs of
            the business Users to ensure end product will enhance our client’s
            business operations.
          </p>
          <p>
            We understand the importance of our client’s desire to commence
            business operations promptly and will co-ordinate with client fitout
            works to streamline commencement of operations. Instances where the
            existing business are to remain operational, we can work in staged
            environments to maintain existing business continuity plans while
            performing the required upgrade works and minimising disruptions.
          </p>
          <p>
            The depth of capabilities, experience and resources within our team
            and trusted professional subcontractors allows us to perform works
            and specialise in the delivery of the following types of commercial
            projects:
          </p>
          <ul>
            <li>Education facilities</li>
            <li>Medical facilities</li>
            <li>Commercial offices</li>
            <li>Retail fitouts</li>
          </ul>
          <p>
            Our success has been attributed to the following qualities advocated
            by our management and project teams:
          </p>
          <ul>
            <li>Quality workmanship</li>
            <li>Attention to detail</li>
            <li>Collaborative nature</li>
            <li>Clear communication</li>
            <li>Being client focused</li>
          </ul>
          <p>
            We have in-house capability and experience to provide the following
            services:
          </p>
          <ul>
            <li>Concept design feasibility assessment</li>
            <li>
              Early Contractor Involvement (ECI) and cost planning value
              management
            </li>
            <li>Lump Sum construction contracts</li>
            <li>Cost Plus construction contracts</li>
            <li>Design and Construct contracts</li>
            <li>Project management services from conceptual to delivery</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
